<template lang='pug'>
	.interesting-things
		.header
			.content.constrainer
				img.header-image(src='@/assets/jpg/interestingplaces.jpg')
				h1.title Interesting places.
				.dashes
					.dash(v-for='n in 5')
				.para.fs Enjoy your drive and check out some of Iceland´s most iconic landmarks on your route or at your destination.
		.body-section.gutter.constrainer
			//- .section-pad
			//- 	BookBanner(
			//- 		:bannerNumber="4"
			//- 	)
			.bluecarrental-banner
				BlueCarRentalBanner(
					:image="{ url: require('@/assets/bluecarrental/image5.jpg') }"
					text="Your trip around our beautiful island relies heavily on your car. We recommend our visitors to use Blue Car Rental."
				)
			//- .space-interesting-one.main-desktop
			//- 	.adspace
			//- 		Adspace(:key='$store.state.keydex + 8' :id='644')
			//-.space-interesting-one.main-mobile
				.adspace
					Adspace(:key='$store.state.keydex + 800' :id='670')
			.pick-a-location
				.topcreated
					h1.title Pick a location on the map
			.pick-a-location.desktop
				.top
					.category-picker(v-click-outside='closeDropA' :class='{ active: showDropA }')
						.heading.clickable(@click='showDropA = !showDropA')
							.text Pick categories
							img.caret(src='@/assets/icons/caret_black.svg')
						.content(:class='{ active: showDropA }')
							.item.clickable(v-for='item in categorySelection' @click='item.selected = !item.selected; pagination = 6;' :class='{ selected: item.selected }')
								.checkmark
									img(src='@/assets/check2.svg')
								.text {{ item.name }}
			.iceland
				img.austur(src='@/assets/map/yellow/austur.svg' usemap='#image-map' :class='{ active: selectedMapParts["austur"] }')
				img.nordur(src='@/assets/map/yellow/nordur.svg' usemap='#image-map' :class='{ active: selectedMapParts["nordur"] }')
				img.rvk(src='@/assets/map/yellow/rvk.svg' usemap='#image-map' :class='{ active: selectedMapParts["rvk"] }')
				img.sudur(src='@/assets/map/yellow/sudur.svg' usemap='#image-map' :class='{ active: selectedMapParts["sudur"] }')
				img.vestfirdir(src='@/assets/map/yellow/vestfirdir.svg' usemap='#image-map' :class='{ active: selectedMapParts["vestfirdir"] }')
				img.vestur(src='@/assets/map/yellow/vestur.svg' usemap='#image-map' :class='{ active: selectedMapParts["vestur"] }')
				img.reykjanes(src='@/assets/map/yellow/reykjanes.svg' usemap='#image-map' :class='{ active: selectedMapParts["reykjanes"] }')
				img.austur(src='@/assets/map/blue/austur.svg' usemap='#image-map' :class='{ inactive: selectedMapParts["austur"] }')
				img.nordur(src='@/assets/map/blue/nordur.svg' usemap='#image-map' :class='{ inactive: selectedMapParts["nordur"] }')
				img.rvk(src='@/assets/map/blue/rvk.svg' usemap='#image-map' :class='{ inactive: selectedMapParts["rvk"] }')
				img.sudur(src='@/assets/map/blue/sudur.svg' usemap='#image-map' :class='{ inactive: selectedMapParts["sudur"] }')
				img.vestfirdir(src='@/assets/map/blue/vestfirdir.svg' usemap='#image-map' :class='{ inactive: selectedMapParts["vestfirdir"] }')
				img.vestur(src='@/assets/map/blue/vestur.svg' usemap='#image-map' :class='{ inactive: selectedMapParts["vestur"] }')
				img.reykjanes(src='@/assets/map/blue/reykjanes.svg' usemap='#image-map' :class='{ inactive: selectedMapParts["reykjanes"] }')
			map(name="image-map")
				area(@click='selectMap(1)' coords="120,139,162,115,169,69,110,9,74,12,44,52,11,114,0,130,29,141,70,131,95,131" shape="poly")
				area(@click='selectMap(2)' coords="125,147,98,164,102,173,126,176,140,167,133,184,114,182,90,181,22,203,34,224,102,219,118,256,129,263,125,272,139,276,156,284,229,227" shape="poly")
				area(@click='selectMap(3)' coords="146,286,150,308,128,317,120,293,131,287" shape="poly")
				area(@click='selectMap(4)' coords="238,232,158,295,158,310,141,323,151,337,179,338,221,365,248,374,299,383,345,370,360,343,401,341,452,296,478,282,503,282,518,266,398,215" shape="poly")
				area(@click='selectMap(5)' coords="406,206,522,255,572,190,570,172,568,128,530,106,530,93,506,94,516,78,513,55,499,63" shape="poly")
				area(@click='selectMap(6)' coords="237,225,394,205,492,61,497,37,514,23,502,15,466,41,455,33,458,17,444,7,438,1,410,4,414,28,415,48,395,49,383,39,372,49,365,73,355,65,342,46,330,47,320,46,325,76,334,91,332,106,315,72,303,49,294,40,281,49,263,59,258,74,262,101,250,86,231,53,210,58,217,103,205,121,201,106,181,121,179,136,174,156,169,122,137,140,131,148" shape="poly")
				area(@click='selectMap(7)' coords="105,313,96,301,91,311,93,334,115,333,132,334,135,319,117,309" shape="poly")
			.pick-a-location.mobile
				.top
					.category-picker(v-click-outside='closeDropB' :class='{ active: showDropB }')
						.heading.clickable(@click='showDropB = !showDropB')
							.text Pick categories
							img.caret(src='@/assets/icons/caret_black.svg')
						.content(:class='{ active: showDropB }')
							.item.clickable(v-for='item in categorySelection' @click='item.selected = !item.selected; pagination = 6;' :class='{ selected: item.selected }')
								.checkmark
									img(src='@/assets/check2.svg')
								.text {{ item.name }}
			.area-details
				h1.title {{ partContent[selected].title }}
				.dashes
					.dash(v-for='n in 5')
				.para.fs(v-for='para in partContent[selected].para') {{ para }}
			.filter-results
				.item(v-for='item, i in spliced' :class='{ right: i % 2 === 0 }')
					.left.mobile
						img.big-pic(:src='item.image')
					.left.desktop(v-if='i % 2 !== 0')
						img.big-pic(:src='item.image')
					.right
						.mini-title {{ item.name }}
						.point-section
							.point
								.left
									img(src='@/assets/icons/blue_dots.svg')
								.right
									span(v-for='point, index in item.categories' v-if='index !== item.categories.length - 1') {{ point }},&nbsp
									span(v-for='point, index in item.categories' v-if='index === item.categories.length - 1') {{ point }}
							.point(v-for='point in item.points' v-if='!item.categories.includes(point)')
								.left
									img(src='@/assets/icons/blue_dots.svg')
								.right {{ point }}
						.more-information
							a.button(:href='`https://www.google.com/search?q=${item.name}`' target='_blank')
								.text Google it
								img.arrow(src='@/assets/icons/arrow-blue.svg')
							a.see-on-map(:href='item.mapsUrl' target='_blank') See on map
					.left.desktop(v-if='i % 2 === 0')
						img.big-pic(:src='item.image')
			.see-more.clickable(@click='nextPage' v-show='this.filteredPlaces.length && this.filteredPlaces.length !== this.spliced.length') View more
			//- .bluecarrental-banner
			//- 	BlueCarRentalBanner(
			//- 		:image="{ url: require('@/assets/bluecarrental/image7.jpg') }"
			//- 		text-align="left"
			//- 	)
			.section-pad
				BlueCarRentalBanner(
					:image="{ url: require('@/assets/bluecarrental/image1.jpg') }"
					text="Safe.is recommends Blue Car Rental for booking and renting safe, reliable cars with the best service possible. Driving around our beautiful island relies heavily on your rental car."
					text-align="left"
				)
</template>

<script>
import ClickOutside from 'vue-click-outside';
export default {
	name: 'InterestingThings',
	metaInfo: {
		title: 'Safe.is - Interesting places.',
		meta: [
			{
				name: 'og:site_name',
				property: 'og:site_name',
				content: 'Safe.is'
			},
			{
				name: 'og:url',
				property: 'og:url',
				content: 'https://safe.is/interesting-places/index.html'
			},
			{
				name: 'og:image',
				property: 'og:image',
				content: 'https://safe.is/interesting/glacier.jpg'
			},
			{
				name: 'og:title',
				property: 'og:title',
				content: 'Safe.is - Interesting places.'
			},
			{
				name: 'og:description',
				property: 'og:description',
				content: 'Enjoy your drive and check out some of Iceland\'s most iconic landmarks and interesting places on your driving route in Iceland.'
			},
			{
				name: 'description',
				property: 'description',
				content: 'Enjoy your drive and check out some of Iceland\'s most iconic landmarks and interesting places on your driving route in Iceland.'
			}
		]
	},
	directives: {
		ClickOutside
	},
	data: function () {
		return {
			selectedMapParts: {
				austur: false,
				nordur: false,
				rvk: false,
				sudur: false,
				vestfirdir: true,
				vestur: false,
				reykjanes: false
			},
			postnumer: {
				'F': [101, 104, 105, 108, 109, 110, 113, 116, 170, 190, 200, 201, 203, 210, 220, 221, 225, 270, 271],
				'E': [230, 233, 235, 240, 245, 250, 260],
				'G': [276, 300, 301, 310, 311, 320, 340, 345, 350, 355, 356, 360, 371, 380],
				'A': [400, 401, 410, 415, 420, 425, 430, 451, 460, 465, 470, 471],
				'B': [500, 510, 512, 520, 524, 530, 531, 540, 541, 545, 550, 551, 560, 565, 566, 570, 580, 600, 601, 603, 610, 611, 621, 625, 630, 640, 641, 645, 650, 660, 661, 670, 671, 675, 680, 681],
				'C': [685, 690, 700, 701, 710, 715, 720, 730, 735, 740, 750, 755, 760, 765],
				'D': [780, 781, 785, 800, 801, 810, 815, 816, 820, 825, 840, 845, 850, 851, 860, 861, 870, 871, 880, 900]
			},
			partContent: {
				austur: {
					title: 'East Iceland.',
					para: [
						'East of Iceland is a perfect driving destination to satisfy your appetite for not only untouched pure nature, but also hiking, swimming, cycling, skiing, horse riding, relaxing spa treatment, and delicious Icelandic food. This area offers great diversity of wildlife: birds, seals and even wild reindeers if you are lucky. This area is characterized by the volcanic ash fertile soil, warmest summer temperature of the country, and mystic beautiful fogs of the east fjords. By driving eastern coastline, you will see countless fjords and fascinating sceneries of the Northern Atlantic. You will observe numerous, cute fishing towns that will offer you authentic unforgettable atmosphere and warm charm.',
						'Roads here are both gravel and asphalt. The main roads are pawed roads (with asphalt) but there are several narrow bridges, where one has to drive very carefully. Some roads in the East are gravel and, thus, require extra cautious driving.'
					]
				},
				nordur: {
					title: 'North Iceland.',
					para: [
						'By visiting North, you\'ll find everything you need for an amazing and unforgettable experience. There are plenty of magnificent mountains, hiking routes, lagoons, spas, horse riding farms, whale watching and beautiful ocean sceneries for you to explore. Even though Northern Lights cannot be ordered for a specific day in Iceland, it is often on a “menu” here in the North, especially if you are lucky and the atmosphere temperature decides to jump.',
						'While travelling to the North, one better be informed about specific weather conditions and study tips on driving over Icelandic highlands well in advance. Majority of the roads leading to the North are asphalt and good to drive. We highly recommend extra cautious driving while taking that road trip.'
					]
				},
				rvk: {
					title: 'Capital Area.',
					para: [
						'The capital area is a home to approximately 130,000 inhabitants and a very welcoming spot where a curious visitor will find all kind of activities. If you stay in the city, you will have a chance to explore interesting museums, enjoy delicious local Icelandic food, and observe fascinating views from striking concrete church Hallgrimskirkja and rotating Perlan glass dome and much-much more.',
						'In case you will drive around the capital area- thirty, sixty minutes’ drive from here, will bring you to the limitless possibilities for hikes, horse- riding tours, neighborhood public pools or active geothermal areas.'
					]
				},
				sudur: {
					title: 'South Iceland.',
					para: [
						'South of Iceland is an extraordinary and beautiful place to visit. It is the most traveled destination in Iceland, easily accessible from the capital area and open for driving all year round. While travelling the South, you will find great variety of landscape features, created by the combined forces of rivers, glacial ice, and volcanic and geothermal activity. Such places as Vatnajökull National Park, Jökulsárlón - melting glacier lagoon, “Golden Circle” route are all permanent residents of this part of the country. We recommend you allocate double amount of time for any trips you take in Iceland that was calculated by Google maps for you. Route of one and a half hour, for example, might easily take three hours to complete in Iceland. This is due to the nature of our roads and magic Instagramable landscape views on the way!',
						'The main roads in the South are pawed roads (asphalt), thus comfortable to drive, but there are also several narrow bridges where you should drive with extreme care. Please, keep in mind that you always need to check weather conditions before driving in Iceland, especially in wintertime.'
					]
				},
				vestfirdir: {
					title: 'The Westfjords.',
					para: [
						'While driving in the Westfjords you will see epic unspoiled nature and ancient landscapes. We recommend you visit “the Jewel of the West fjords” the waterfall Dynjandi, pink-sand beaches of Rauðasandur, birdwatching cliffs of Látrabjarg and try to find puffins in natural environment.',
						'The roads of the area are not as regularly maintained as in the rest of the country during wintertime. Circuitous roads of the WestFjords are covered by ice and snow during the winter, and mainly accessible only from May to October.'
					]
				},
				vestur: {
					title: 'West Iceland.',
					para: [
						'West of Iceland is gorgeous by its picturesque nature mystery and wealth of the historical saga heritage, that is prose narratives, stories based on historical events in Iceland of the 9th, 10th, early 11th centuries.',
						'Most of the roads in the West are pawed roads (asphalt) and are good to drive. However, the roads closer to the highlands in the eastern part of the area, are often gravel and require extra cautious driving.'
					]
				},
				reykjanes: {
					title: 'Reykjanes.',
					para: [
						'Reykjanes Peninsula is a UNESCO Global Geopark, and a picturesque, convenient driving destination. The area is quite famous for its’ volcanic and geothermal activity. It is a home to fascinating wonders of nature and exciting man-made constructions. Here we recommend you visit: the deepest lake in Iceland Kleifarvatn, hot springs geothermal area Seltún, accessible Krisuvikurberg Cliffs, the biggest mud pool in Iceland Gunnuhver, and of course, world famous turquoise baths of Blue Lagoon.',
						'Roads to all those places are, mainly, asphalt and have well-organized designated parking areas. However, we highly recommend extra cautious driving on Icelandic roads all year around.'
					]
				}
			},
			selected: 'vestfirdir',
			showDropA: false,
			showDropB: false,
			places: [],
			categorySelection: [],
			pagination: 6,
			images: {
				animals: ['Sheep roundups', 'Reindeers', 'Wildlife', 'Foxes', 'Whales', 'Birds', 'Seals'],
				fall: ['Pillar of rock', 'Hiking trails', 'Waterfalls', 'Ravine and Canyons', 'Caves', 'Geological formation', 'Coefficients'],
				geo: ['Lava', 'Geothermal area', 'Natural pools'],
				glacier: ['Glacier lagoons', 'Glaciers', 'Spectacular view', 'Volcanos', 'Periscope'],
				history: ['Cultural heritage'],
				religion: ['Churches', 'Cemeteries'],
				spring: ['Forests', 'Berries', 'Vegetation', 'Uncommon herbs'],
				statues: ['Monuments', 'Architecture', 'Lighthouses', 'Gardens', 'Statues and memorials', 'Geological monuments', 'Protected antiquities', 'Historical places', 'Stone Cairns', 'Power stations'],
				summer: ['Beaches']
			}
		};
	},
	computed: {
		selectedCategories: function () {
			let categories = [];
			this.categorySelection.forEach((category) => {
				if (category.selected) {
					categories.push(category.name);
				}
			});
			return categories;
		},
		spliced: function () {
			let spliced = this.filteredPlaces.slice(0, this.pagination);
			for (let i = 0; i < spliced.length; i++) {
				let animals = 0;
				let fall = 0;
				let geo = 0;
				let glacier = 0;
				let history = 0;
				let religion = 0;
				let spring = 0;
				let statues = 0;
				let summer = 0;
				for (let ii = 0; ii < spliced[i].categories.length; ii++) {
					if (this.images['animals'].includes(spliced[i].categories[ii])) {
						animals++;
					} else if (this.images['fall'].includes(spliced[i].categories[ii])) {
						fall++;
					} else if (this.images['geo'].includes(spliced[i].categories[ii])) {
						geo++;
					} else if (this.images['glacier'].includes(spliced[i].categories[ii])) {
						glacier++;
					} else if (this.images['history'].includes(spliced[i].categories[ii])) {
						history++;
					} else if (this.images['religion'].includes(spliced[i].categories[ii])) {
						religion++;
					} else if (this.images['spring'].includes(spliced[i].categories[ii])) {
						spring++;
					} else if (this.images['statues'].includes(spliced[i].categories[ii])) {
						statues++;
					} else if (this.images['summer'].includes(spliced[i].categories[ii])) {
						summer++;
					}
				}

				let images = { animals, fall, geo, glacier, history, religion, spring, statues, summer };
				let largest = 'animals';
				let largestValue = animals;

				Object.keys(images).forEach((key, index) => {
					if (images[key] > largestValue) {
						largest = key;
						largestValue = images[key];
					}
				});

				spliced[i].image = `/interesting/${largest}.jpg`;

				if (!spliced[i].mapsUrl.includes('?q=')) {
					let splitA = spliced[i].mapsUrl.split('/');
					let location = splitA[splitA.length - 1].split(',');
					spliced[i].mapsUrl = `https://maps.google.com/?q=${location}&ll=${location}&z=8`;
				}
				for (let ii = 0; ii < spliced[i].points.length; ii++) {
					if (spliced[i].points[ii] === 'High attraction (interesting and likely destination))') {
						spliced[i].points[ii] = 'High attraction (interesting and likely destination)';
						break;
					}
				}
			}
			return spliced;
		},
		filteredPlaces: function () {
			let areaCode = '';
			if (this.selectedMapParts['vestfirdir']) {
				areaCode = 'A';
			} else if (this.selectedMapParts['vestur']) {
				areaCode = 'G';
			} else if (this.selectedMapParts['rvk']) {
				areaCode = 'F';
			} else if (this.selectedMapParts['sudur']) {
				areaCode = 'D';
			} else if (this.selectedMapParts['austur']) {
				areaCode = 'C';
			} else if (this.selectedMapParts['nordur']) {
				areaCode = 'B';
			} else if (this.selectedMapParts['reykjanes']) {
				areaCode = 'E';
			}
			let places = [];
			this.places.forEach((place) => {
				if (this.postnumer[areaCode].includes(place.postnumer)) {
					for (let i = 0; i < place.categories.length; i++) {
						if (!this.selectedCategories.length) {
							places.push(place);
							break;
						} else if (this.selectedCategories.includes(place.categories[i])) {
							places.push(place);
							break;
						}
					}
				}
			});
			return places;
		}
	},
	mounted: function () {
		this.$store.state.keydex += 1;
	},
	created: function () {
		this.axios.get(('https://us-central1-safe-3ee55.cloudfunctions.net/places')).then((result) => {
			this.places = result.data.places;
			this.categorySelection = result.data.categories;
		}).catch((error) => {
			console.log(error);
		});
	},
	methods: {
		nextPage: function () {
			this.pagination += 8;
		},
		closeDropA: function () {
			this.showDropA = false;
		},
		closeDropB: function () {
			this.showDropB = false;
		},
		sortNumber: function (a, b) {
			return a - b;
		},
		selectMap: function (id) {
			this.pagination = 6;
			if (id === 1) {
				this.selectedMapParts['vestfirdir'] = true;
				this.selected = 'vestfirdir';
				this.selectedMapParts['vestur'] = false;
				this.selectedMapParts['rvk'] = false;
				this.selectedMapParts['sudur'] = false;
				this.selectedMapParts['austur'] = false;
				this.selectedMapParts['nordur'] = false;
				this.selectedMapParts['reykjanes'] = false;
			} else if (id === 2) {
				this.selectedMapParts['vestur'] = true;
				this.selected = 'vestur';
				this.selectedMapParts['vestfirdir'] = false;
				this.selectedMapParts['rvk'] = false;
				this.selectedMapParts['sudur'] = false;
				this.selectedMapParts['austur'] = false;
				this.selectedMapParts['nordur'] = false;
				this.selectedMapParts['reykjanes'] = false;
			} else if (id === 3) {
				this.selectedMapParts['rvk'] = true;
				this.selected = 'rvk';
				this.selectedMapParts['vestfirdir'] = false;
				this.selectedMapParts['vestur'] = false;
				this.selectedMapParts['sudur'] = false;
				this.selectedMapParts['austur'] = false;
				this.selectedMapParts['nordur'] = false;
				this.selectedMapParts['reykjanes'] = false;
			} else if (id === 4) {
				this.selectedMapParts['sudur'] = true;
				this.selected = 'sudur';
				this.selectedMapParts['vestfirdir'] = false;
				this.selectedMapParts['vestur'] = false;
				this.selectedMapParts['rvk'] = false;
				this.selectedMapParts['austur'] = false;
				this.selectedMapParts['nordur'] = false;
				this.selectedMapParts['reykjanes'] = false;
			} else if (id === 5) {
				this.selectedMapParts['austur'] = true;
				this.selected = 'austur';
				this.selectedMapParts['vestfirdir'] = false;
				this.selectedMapParts['vestur'] = false;
				this.selectedMapParts['rvk'] = false;
				this.selectedMapParts['sudur'] = false;
				this.selectedMapParts['nordur'] = false;
				this.selectedMapParts['reykjanes'] = false;
			} else if (id === 6) {
				this.selectedMapParts['nordur'] = true;
				this.selected = 'nordur';
				this.selectedMapParts['vestfirdir'] = false;
				this.selectedMapParts['vestur'] = false;
				this.selectedMapParts['rvk'] = false;
				this.selectedMapParts['sudur'] = false;
				this.selectedMapParts['austur'] = false;
				this.selectedMapParts['reykjanes'] = false;
			} else if (id === 7) {
				this.selectedMapParts['reykjanes'] = true;
				this.selected = 'reykjanes';
				this.selectedMapParts['vestfirdir'] = false;
				this.selectedMapParts['vestur'] = false;
				this.selectedMapParts['rvk'] = false;
				this.selectedMapParts['sudur'] = false;
				this.selectedMapParts['austur'] = false;
				this.selectedMapParts['nordur'] = false;
			}
		}
	}
};
</script>

<style scoped lang='less'>
.interesting-things {
	.space-interesting-one {
		min-height: 1px;
		&.main-mobile {
			display: none;
			margin-top: 48px;
			margin-bottom: 48px;
		}
		&.main-desktop {
			margin-top: 96px;
			margin-bottom: 96px;
		}
		@media screen and (max-width: 768px) {
			&.main-mobile {
				display: block;
			}
			&.main-desktop {
				// display: none;
			}
		}
	}
	.body-section {
		.see-more {
			margin-top: 96px;
			@media screen and (max-width: 1220px) {
				margin-top: 48px;
			}
			margin-left: auto;
			margin-right: auto;
			width: 214px;
			height: 48px;
			box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.07);
			background-color: #00476e;
			font-size: 16px;
			font-weight: 500;
			font-style: normal;
			font-stretch: normal;
			line-height: 48px;
			letter-spacing: 1.2px;
			text-align: center;
			color: #ffffff;
		}
		.filter-results {
			.item {
				margin-top: 143px;
				@media screen and (max-width: 768px) {
					margin-top: 88px;
				}
				display: grid;
				grid-template-columns: 1fr 1fr;
				grid-column-gap: 40px;
				&.right {
					grid-template-columns: 1fr 1fr;
				}
				@media screen and (max-width: 1220px) {
					grid-template-columns: 1fr !important;
				}
				.left {
					&.mobile {
						position: relative;
						max-height: 60vh;
						min-height: 200px;
						max-width: 100%;
						img {
							object-fit: cover;
							object-position: bottom;
							max-height: 60vh;
							min-height: 200px;
						}
						@media screen and (min-width: 1221px) {
							display: none;
						}
					}
					&.desktop {
						@media screen and (max-width: 1220px) {
							display: none;
						}
					}
				}
				.more-information {
					display: grid;
					grid-template-columns: 244px 130px;
					@media screen and (max-width: 1220px) {
						grid-template-columns: 155px 105px;
					}
					@media screen and (max-width: 360px) {
						grid-template-columns: 1fr;
					}
					grid-column-gap: 32px;
					margin-top: 58px;
					.see-on-map {
						left: 0px;
						position: relative;
						border-bottom: 2px solid black;
						padding-bottom: 4px;
						max-width: 130px;
						height: 38px;
						font-size: 18px;
						font-weight: 600;
						font-style: normal;
						font-stretch: normal;
						line-height: 3.33;
						letter-spacing: 1.5px;
						color: #000000;
						@media screen and (max-width: 1220px) {
							transform: translate3d(0px, -12px, 0px);
							font-size: 14px;
						};
						@media screen and (max-width: 360px) {
							max-width: 105px;
						}
					}
					.button {
						position: relative;
						display: grid;
						grid-template-columns: 80% 20%;
						height: 48px;
						width: 244px;
						background-color: #FFFFFF;
						box-shadow: 0px 3px 5px 1px rgba(0,0,0,0.07);
						border-left: 4px solid #AF7595;
						.text {
							color: black;
							text-align: left;
							height: 30px;
							width: 161px;
							font-size: 18px;
							font-weight: 600;
							letter-spacing: 1.5px;
							line-height: 48px;
							padding-left: 32px;
						}
						.arrow {
							position: relative;
							top: 50%;
							transform: translate3d(0px, -50%, 0px);
						}
						@media screen and (max-width: 1220px) {
							grid-template-columns: 80% 20%;
							width: 155px;
							height: 32px;
							border-left: 2px solid #AF7595;
							.text {
								width: 102px;
								height: 19px;
								font-family: Montserrat;
								font-size: 14px;
								font-weight: 600;
								font-style: normal;
								font-stretch: normal;
								line-height: 2.14;
								letter-spacing: 1.2px;
								color: #000000;
							}
							.arrow {
								height: 12px;
								right: -12px;
							}
						}
						@media screen and (max-width: 360px) {
							margin-bottom: 24px;
						}
					}
				}
				.point-section {
					.point {
						display: grid;
						grid-template-columns: 53px auto;
						@media screen and (max-width: 1220px) {
							grid-template-columns: 24px auto;
						}
						grid-column-gap: 16px;
						margin-bottom: 21px;
						.left {
							position: relative;
							img {
								width: 100%;
								position: absolute;
								top: 19px;
							}
						}
						.right {
							max-width: 549px;
							font-family: Montserrat;
							font-size: 18px;
							@media screen and (max-width: 1220px) {
								font-size: 14px;
							}
							font-weight: normal;
							font-style: normal;
							font-stretch: normal;
							line-height: 1.62;
							letter-spacing: 1.8px;
							color: #000000;
						}
					}
					.point {
						text-align: left;
					}
				}
				.left {
					.big-pic {
						width: 100%;
						object-fit: cover;
					}
				}
				.right {
					.mini-title {
						padding-bottom: 24px;
						text-align: left;
						min-height: 56px;
						font-size: 34px;
						@media screen and (max-width: 1220px) {
							margin-top: 48px;
							font-size: 26px;
						}
						@media screen and (max-width: 768px) {
							margin-top: 40px;
						}
						font-weight: 600;
						font-style: normal;
						font-stretch: normal;
						line-height: 1.35;
						letter-spacing: 1.7px;
						color: #00476e;
					}
				}
			}
		}
		.bluecarrental-banner {
			margin-top: 96px;
			margin-bottom: 96px;
		}
		.area-details {
			@media screen and (min-width: 1221px) {
				margin-top: 129px;
			}
			.title {
				text-align: left;
				max-width: 568px;
				color: #000000;
			}
			.dashes {
				margin-bottom: 47px;
			}
			.para {
				padding-bottom: 24px;
				text-align: left;
				max-width: 1024px;
				color: #000000;
			}
		}
		.iceland {
			position: relative;
			margin-top: 134px;
			height: 389px;
			width: 573px;
			margin-left: auto;
			margin-right: auto;
			img {
				position: absolute;
				top: 0px;
				left: 0px;
				width: 573px;
				transition: .3s;
				opacity: 1;
				&.active {
					opacity: 1;
					transform: scale(1.01);
				}
				&.inactive {
					opacity: 0;
				}
			}
			@media screen and (max-width: 1220px) {
				margin-top: 86px;
			}
			@media screen and (max-width: 768px) {
				transform-origin: 0 0;
				margin-left: 0px;
				margin-right: 0px;
				left: 50%;
				transform: scale(.8) translate3d(-50%, 0px, 0px);
			}
			@media screen and (max-width: 600px) {
				margin-bottom: -118px;
				transform: scale(.6) translate3d(-50% , 0px, 0px);
			}
			@media screen and (max-width: 400px) {
				margin-bottom: -176px;
				transform: scale(.45) translate3d(-50% , 0px, 0px);
			}
		}
		.pick-a-location {
			&.desktop {
				@media screen and (max-width: 1220px) {
					display: none;
				}
			}
			&.mobile {
				@media screen and (min-width: 1221px) {
					display: none;
				}
				margin-bottom: 96px;
			}
			.top {
				position: relative;
				display: grid;
				grid-template-columns: 1fr 1fr;
				@media screen and (max-width: 1220px) {
					grid-template-columns: 1fr;
				}
				z-index: 30;
				.category-picker {
					position: absolute;
					right: 0px;
					background-color: white;
					outline: solid 1px #f9db49;
					padding-bottom: 0px;
					padding-left: 32px;
					width: 304px;
					height: 48px;
					transition: .3s;
					transition-delay: .3s;
					@media screen and (max-width: 1220px) {
						margin-top: 36px;
						position: relative;
						left: 50%;
						transform: translate3d(-50%, 0px, 0px);
					}

					@media screen and (max-width: 400px) {
						width: calc(~'100vw - 82px');
					}
					.heading {
						display: grid;
						grid-template-columns: 1fr 1fr;
						.caret {
							position: absolute;
							top: 16px;
							left: 100%;
							transition: .3s;
							transform: translate3d(-100%, 0px, 0px) scaleY(1);
							padding-right: 24px;
						}
						.text {
							text-align: left;
							width: 232.3px;
							height: 48px;
							line-height: 48px;
							font-size: 15px;
							font-weight: 600;
							font-style: normal;
							font-stretch: normal;
							letter-spacing: 0.6px;
							color: #4d4d4d;
						}
					}
					&.active {
						.heading {
							.caret {
								transform: translate3d(-100%, 0px, 0px) scaleY(-1);
							}
						}
						transition-delay: 0s;
						width: 552px;
						height: 751px;
						@media screen and (max-width: 640px) {
							width: 304px;
							height: 576px;
							max-width: calc(~'100vw - 82px');
						}
					}
					.content {
						position: relative;
						z-index: 30;
						display: grid;
						grid-template-columns: 1fr 1fr;
						@media screen and (max-width: 640px) {
							grid-template-columns: 1fr;
							height: 518px;
							overflow-y: scroll;
							margin-right: 31px;
							&::-webkit-scrollbar {
								width: 4px;
							}
							&::-webkit-scrollbar-track {
								background: #fafafa;
							}
							&::-webkit-scrollbar-thumb {
								background: #f9db49;
								border-radius: 4.5px;
							}
						}
						opacity: 0;
						pointer-events: none;
						transition: .3s;
						transition-delay: 0s;
						&.active {
							transition-delay: .3s;
							opacity: 1;
							pointer-events: initial;
						}
						.item {
							display: grid;
							grid-template-columns: 16px auto;
							grid-column-gap: 16px;
							margin-bottom: 15px;
							margin-left: 1px;
							margin-top: 1px;
							.checkmark {
								position: relative;
								top: 50%;
								img {
									transition: .3s;
									opacity: 0;
								}
								width: 16px;
								height: 16px;
								line-height: 16px;
								outline: solid 1px #f9db49;
								transition: .3s;
								transform: translate3d(0px, -50%, 0px) rotate3d(1, 1, 0, 180deg);
							}
							&.selected {
								.checkmark {
									transform: translate3d(0px, -50%, 0px) rotate3d(1, 1, 0, 0deg);
									background-color: #f9db49;
									img {
										opacity: 1;
									}
								}
							}
							.text {
								text-align: left;
								max-width: 232px;
								min-height: 16px;
								font-size: 15px;
								font-weight: normal;
								font-style: normal;
								font-stretch: normal;
								line-height: normal;
								letter-spacing: 0.6px;
								color: #4d4d4d;
							}
						}
					}
				}
				.title {
					text-align: left;
					max-width: 445px;
					color: #000000;
				}
			}
		}
	}
	.header {
		height: 628px;
		max-height: 628px;
		background-image: url('~@/assets/jpg/interestingthings_header.jpg');
		background-size: cover;
		background-position: 50% 100%;
		.header-image {
			display: none;
		}
		@media screen and (max-width: 1220px) {
			background-image: none;
			height: initial;
			max-height: initial;
			.header-image {
				margin-top: 48px;
				display: block;
				width: 100%;
				max-height: 440px;
				object-fit: cover;
				object-position: bottom;
			}
		}
		.content {
			position: relative;
			top: 0px;
			left: 0px;
			text-align: left;
			.title {
				padding-top: 68px;
				@media screen and (max-width: 1220px) {
					padding-top: 32px;
				}
				position: relative;
				max-width: 492px;
			}
			.para {
				padding-top: 38px;
				max-width: 348px;
			}
		}
	}
	.dashes {
		margin-top: 38px;
		display: grid;
		grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
		width: 60px;
		.dash {
			width: 8px;
			height: 2px;
			background-color: #F9DB49;
		}
	}
}
</style>
